<template>
    <div></div>

</template>

<script>
    export default {
        name: "detail.vue"
    }
</script>

<style scoped>

</style>
